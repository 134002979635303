<!--  -->
<template>
  <div style="width: 1600px">
    <div class="sysMsg">
      <el-date-picker
              v-model="date"
              align="right"
              type="date"
              :placeholder="$t('ChooseDate')"
              @change="selectDay"
              value-format="yyyy-MM-dd"
              style="float: left; margin-left: 20px">
      </el-date-picker>
      {{$t('SystemMessage')}}
    </div>
    <!--消息列表-->
    <div class="msgs" v-for="msg in msgData" :key="msg.id">
      <h2 style="margin-bottom: 10px">{{msg.title}}</h2>
      <span class="content">{{msg.content | ellipsis}}</span>
      <span @click="readMsg(msg.title,msg.content,msg.sendTime)" v-show="msg.content.length > 126" style="cursor: pointer;align-self: flex-end">点击阅读全文</span>
      <div class="msgTime">{{msg.sendTime}}</div>
    </div>

    <!--查看消息弹窗-->
    <el-dialog :close-on-click-modal="false" :visible.sync="msgDialog">
      <el-form :model="dialogContentForm">
        <div class="msgsDialog">
          <h2 style="margin-bottom: 10px">{{dialogContentForm.title}}</h2>
          <div class="content">{{dialogContentForm.content}}</div>
          <span class="msgTime">{{dialogContentForm.time}}</span>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="msgDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
    import api from '@/fetch/api'
export default {
  data() {
//            读取本地个人信息
      const data = JSON.parse(localStorage.getItem('cacheChamber')).adminData
    return {
        chamberId:data.chamberId,
        readBtn:false,
        msgDialog:false,
        date:'',
        msgData:[],

        init:{
            chamberId:data.chamberId,
            page:1,
        },

        dialogContentForm:{
          title:'',
          content:'',
          time:'',
        },
    };
  },
    filters:{
        ellipsis(value){
            if (!value) return ''
            if (value.length > 326) {
                return value.slice(0, 326) + '...'
            }
            return value
        }
    },
    mounted() {
      this.initMsg(1)
    },
    computed:{
      content(value){
          if (!value) return ''
          if (value.length > 326) {
              return value.slice(0, 326) + '...'
          }
      }
    },
  methods: {
      initMsg(page){
          this.init.page = page
          api.getMsgList(this.init).then(res=>{
              console.log('请求成功',res.msg)
              this.msgData = res.data.messageList
          })
      },
      readMsg(title,desc,time){
          this.msgDialog = true;
          this.dialogContentForm.title = title;
          this.dialogContentForm.content = desc;
          this.dialogContentForm.time = time;
      },

//      当选择时间时
      selectDay(){
          this.init.date = this.date;
          this.initMsg(1);
          delete this.init.date
      }
  }
};
</script>
<style lang="scss" scoped>
  .sysMsg{
    width: 98%;
    height:50px;
    line-height: 50px;
    font-size: 20px;
    text-align: center;
    background-color: #425D7B;
    color: white;
  }
  .msgs{
    height: 150px;
    width:98%;
    border-radius: 10px;
    background-color: #AFD2FA;
    margin: 10px 0;
    padding:10px 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    button{
      float: left;
      margin-left: 20px;
      font-size: 14px;
    }
    .msgTime{
      align-self: flex-end;
    }
    .content{
      float: left;
      width:90%;
      word-wrap:break-word;
      word-break:break-all;
      display: inline-block;
    }
  }
  .msgsDialog{
    width:98%;
    border-radius: 10px;
    background-color: #AFD2FA;
    margin: 10px 0;
    padding:10px 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    .msgTime{
      align-self: flex-end;
    }
  }
</style>
